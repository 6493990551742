import request from '@/utils/request'

export function getLetteringList (data) {
  return request({
    url: 'api/Lettering/GetLetteringPage',
    method: 'post',
    data,
  })
}

export function getSupplierLetteringList (data) {
  return request({
    url: 'api/Lettering/GetSupplierLetteringPage',
    method: 'post',
    data,
  })
}

export function getLetteringOptions () {
  return request({
    url: 'api/Lettering/GetLetteringStateOptions',
    method: 'get',
  })
}

export function getLetteringById (id) {
  return request({
    url: 'api/Lettering/GetLetteringById',
    method: 'get',
    params: { id },
  })
}

export function addLettering (data) {
  return request({
    url: `api/Lettering/AddLettering${(!data.id) || ('?id=' + data.id)}`,
    method: 'post',
    data,
  })
}

export function deleteLettering (id) {
  return request({
    url: 'api/Lettering/DeleteLettering',
    method: 'get',
    params: { id },
  })
}

export function downloadFile (url, fileName) {
  return request({
    url: 'api/Lettering/DowloadFile',
    method: 'get',
    params: {
      url,
      fileName,
    },
    responseType: 'blob',
  })
}

export function changeLetteringCheckState (id) {
  return request({
    url: 'api/Lettering/ChangeLetteringCheckState',
    method: 'get',
    params: { id },
  })
}

export function sendLetteringDeivery (data) {
  return request({
    url: 'api/Lettering/SendLettering',
    method: 'post',
    data,
  })
}

export function AdminSendLetteringDeivery (data) {
  return request({
    url: 'api/Lettering/AdminSendLettering',
    method: 'post',
    data,
  })
}
